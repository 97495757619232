import { template as template_5f2ca01eea92484d915d093a526a8e45 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5f2ca01eea92484d915d093a526a8e45(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
