import { template as template_84fa4b4793ea4fb6b4a44583dcac8939 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_84fa4b4793ea4fb6b4a44583dcac8939(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
