import { template as template_295269d5bb284a2180169b3069936b41 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_295269d5bb284a2180169b3069936b41(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_295269d5bb284a2180169b3069936b41(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_295269d5bb284a2180169b3069936b41(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
